import {defineNuxtRouteMiddleware, navigateTo} from "nuxt/app";

export default defineNuxtRouteMiddleware(async (to) => {
  const authStore = useAuthStore();
  await authStore[GET_USER]();
  const config = useRuntimeConfig();

  if (!authStore.isLoggedIn) {
    return;
  }

  switch (true) {
    case to.path.includes("/onboarding/login") ||
      to.path.includes("/reactivate"):
      if (authStore.user?.subscription?.status === "cancelled") {
        return navigateTo(
          config.public.reactivatePageUrl + window.location.search,
          {
            external: true,
          }
        );
      }

      return navigateTo(config.public.accountUrl + window.location.search, {
        external: true,
      });
  }
});
